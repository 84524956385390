.container{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  color:var(--black);
  display: flex;
  flex-direction: column;
}
.requestCamerasNotSucceeded_text{
  margin:5px;
  color:var(--red);
}
.cameras_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.anotherAdminAlredyRequestCamera_text{
  font-size: 0.8rem;
  color:var(--grey);
  margin:10px 0px 10px 10px;
}
  
  .camera_item {
    width: 30%; 
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  .camera_item h3 {
    margin-bottom: 5px;
  }

  .camera_img_container {
    width: 100%;
    display: flex;
    height:100%;
    justify-content: center;
  }
  
  .camera_item img {
    width: 100%; 
    height: auto; 
  }
  button {
    margin: 10px; 
    padding: 10px 20px; 
    color: var(--carteav); 
    border: none;
    background: none;
    cursor: pointer;
  }
  
  button:disabled{
    color: var(--grey);
  }

  .enlarged_camera_img {
    width: 100%;
    height: auto;
  }
  
  .enlarged_camera button {
    margin-bottom: 10px;
  }
  
  /* Media Queries for Tablet View */
  @media only screen and (max-width: 1025px) {
    .camera_item {
      width: 45%;
    }
  }
  
  /* Media Queries for Mobile View */
  @media only screen and (max-width: 480px) {
    .camera_item {
      width: 100%;
    }
  }