.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.my-swal {
  z-index:10000000!important;
}
.text_right{
  margin-top:1vh;
  margin-right:5vw;
  text-align: right;
  font-weight: bold;
  opacity:0.9;
}
.swal-icon{
  position:absolute;
  text-align: right;
  right:0.5vw;
  font-size:30x;
  margin-bottom:auto;
  margin-top:auto;
}

.bodyContainer {
  margin: 0 auto; 
  position: relative;
  top: 100px;
  max-width: 1050px;
  display: flex;
  flex-direction: column;
}


.center {
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    transform:scale(5.5);
    animation: App-logo-spin infinite 1s linear;
  }
}
@font-face { font-family: roboto-regular; 
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); } 
html, body {
  margin: 0 !important;

}

.centered_layout {
  display: grid;
  height: 100vh;
  height:calc(var(--app-height, 1vh) * 100);
  max-height: -webkit-fill-available;
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.bouncing-loader {
  position: relative;
  margin-top: 30%;
  display: flex;
  justify-content: center;
}

.loader_horizontal{
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 4px;
}
.loader_horizontal > div {
  width: 6px;
  height: 6px;
  margin:4px;
  border-radius: 50%;
  background-color: #d28440;
  opacity: 0.4;
  animation: loader_horizontal 0.6s infinite alternate;
}
.my_polyline { 
  color: 'black';
  opacity:0.8;
  stroke-dasharray: 4,15; 
  stroke-width: 4;  
  stroke-linecap:square;
/* animation: loader_horizontal 4s infinite alternate-reverse ; */
}
@keyframes loader_horizontal {
  to {
    transform: translateY(4px);
  }
}
.loader_horizontal > div:nth-child(2) {
  opacity: 0.6;
  animation-delay: 0.2s;
}


.loader_horizontal > div:nth-child(3) {
  animation-delay: 0.4s;
  opacity: 0.8;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div {
  width: 12px;
  height: 12px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #009DDC;
  opacity: 0.8;
  animation: bouncing-loader 0.6s infinite alternate;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes App-logo-spin {
  from {
    
    transform: rotate(360deg) scale(5.5);;
  }
  to {
    transform: rotate(360deg) scale(5.5);;
  }
}
.popUp{
  z-index:500;
  font-weight: bold;
  font-size: 1rem;
  background-color:#FFBC49;
  border-radius: 10px;
  color: #3b3838;
  pointer-events: none;

}
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}