.videoAppBody {
    z-index: 999999;
    position: absolute;
    height: calc(100vh - 400px);
    width: 100%;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

 
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0px;
  position: absolute;
  width: 394px;
  background: #100F15;
  border: 1px solid #DFEBFA;
  box-shadow: 0px 4px 16px 4px rgba(24, 26, 46, 0.3);
  border-radius: 8px;
  visibility: hidden;
  z-index: 9999999;
}

.incomingVoiceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px !important;
  gap: 20px !important;
  width: 400px !important;
  height: 190px !important;
  background: #100F15 !important;
  border: 1px solid #DFEBFA !important;
  box-shadow: 0px 4px 16px 4px rgba(24, 26, 46, 0.3) !important;
  border-radius: 8px !important;
}

.incomigVoiceCallHeader{
  height: 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  position: absolute;
  top: 40px;
  display: flex !important;
  width: 350px !important;
  justify-content: center;
  align-items: center;
  color: #FFFFFF !important;

}

.incomigVoiceCallBodyText{
  font-family: 'Roboto';
  font-style: normal;
  position: absolute;
  top: 73px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF !important;
}

.incomigVoiceCallModal{
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

.declineCallButton{
  cursor: pointer;
  content: url("../../assets/icons/buttons/declineCall.svg");  
}

.answerCallButton{
  cursor: pointer;
  content: url("../../assets/icons/buttons/answerCall.svg");  
}

.videoContainer_userName{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  left: 20px;
  top: 23px;
  
}

.videoContainer_site{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #FFFFFF;
  position: absolute;
  left: 20px;
  top: 55px;

}

.videoContainer_endCallButton{
  position: absolute;
  right: 20px;
  top: 29px;
  cursor: pointer;
  content: url("../../assets/icons/event/buttons/endCall.svg");
}

.videoContainer_extandButton{
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7px;
  content: url("../../assets/icons/event/buttons/extandButton.svg");
}

.videoCall_name_header{
  position: relative;
  margin-bottom: 15px;
  opacity: 0.8;
} 