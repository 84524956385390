

.customModalBody{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2px;
  border-radius: 8px;
  z-index: 9999991 !important; 
  width: min(95vw ,400px) ;
  min-width: 250px;
  overflow-y: auto;
  box-shadow: 0px 4px 16px 4px rgba(24, 26, 46, 0.3);
}



.incomingVoiceCall{
  background-color: var(--black) !important;
  border: 1px solid #DFEBFA !important;
  box-shadow: 0px 4px 16px 4px rgba(24, 26, 46, 0.3) !important;
  color:var(--white) !important;
}


.customModalHeader{
    width: calc(100% - 12px);
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    color: var(--black);
    margin:0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin : 8px 0;
    padding-left: 12px;
}


.customModalSeperator{
    width: 100%;
    border: 1px solid #EBEEF7;
}
.customModalBodyText{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--black) ;
    margin: 18px 0;
}



.customModalButtons{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 22px;
    margin-top : 12px;
}
.customModalButtons > * {
    margin-right: 5px;
    margin-left: 5px; /* Adjust the spacing as needed */
}

.customModalButton{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding:8px 24px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
  font-size: 0.9;
  font-weight: 600;
  cursor: pointer;
}

.cancelButton {
    background-color: white;
    border: 2px solid #CED5EF;
    color:var(--grey);
}


.cancelButton:hover {
    background-color: #CED5EF;
    border-color: #ACB9D8; 
}

.blackButton {
  background-color: var(--black);
  color:var(--white);
}


.blackButton:hover {
  background-color: var(--light_black);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999 !important; 
}


.dangerButton {
    background-color: #FF3239;
    color: white;
  }
  
  .dangerButton:hover {
    background-color: #FF656C;
  }

  .successButton {
    background-color: var(--dark_green);
    color: var(--white);
  }

  .successButton:hover {
    background-color: var(--green);
  }

  .blackButton{
    background-color: var(--black);
  }

  .blackButton:hover {
    background-color: var(--grey);
  }


  .incomingVoiceCall .customModalHeader,
  .incomingVoiceCall .customModalBodyText {
    color: var(--white) !important;
  }