.sidebar {
  position: fixed;
  width: 20vh;
  z-index: 10000000;

}

.regular_appBar{
    background-color: white !important;
    height: 60px !important;
    width: 100vw !important;
    position: fixed !important;
}

.admin_appBar{
    background-color: white !important;
    height: 60px !important;
    width: calc(100vw - 60px ) !important;
    position: fixed !important;
    left: 60px !important;
}

.setting_icon{
    background-color:#E4E6EB !important;
    box-shadow: none !important;
    border-radius: 50% !important; 

}

.setting_icon:hover{
    background-color: #B9BBC1 !important;
}

.setting_icon_active{
    background-color:var(--carteav_light) !important;
    box-shadow: none !important;
    color:var(--carteav) !important;
    border-radius: 50% !important; 
}

.setting_icon_active:hover{
    background-color:var(--carteav_dark) !important;
    color:var(--white) !important;
}





.setting_icon_no_hover{
    background-color:#E4E6EB !important;
    box-shadow: none !important;
    margin-right: 8px !important;
    right: 8px !important;
    border-radius: 50% !important; 

}


.logoutIcon {
    height: 18px !important;
    width: 18px !important;
    border-radius: 50% !important; 
    
  }


.logo_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 0px;
    gap: 8px;
    left:0;
    width: 68px;
    height: 68px;
    margin: 0;
    background: var(--carteav);
    margin-right: 100px;
}
.logoContainer {
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .logoIcon {
    height: 35px !important;
    width: 35px !important;
    padding-right: 4px;
  }

.logo {
    display: grid;
    place-items: center;
    height: 120px;
    margin-top: 2vh;
    font-size: 2rem;
    font-weight: 500;
    color:#3e5060;
    font-family: 'Mochiy Pop P one', sans-serif;
    
}    
.logo:hover{
    opacity: 0.7;
}

.siteManagerRoleTopBar {
    margin-left:20px !important;
    margin-right: 10px;
    font-family: 'Roboto';
    font-weight: 600;
    letter-spacing: 0;
    color: #070A0E;
    text-decoration: none;
    display: flex;
  }


.item {
    position: relative;
    display: flex;
    height:4rem;
    align-items: center;
    place-content:flex-start;
    padding-left:0.7rem;
    margin:1rem;
    font-size:1.3rem;
    font-weight:400;
    color:#3e5060;
    transition: color 0.2s ease-in-out;
}
.item:hover{  
    padding-left:1.2rem;
    color: #009DDC;
}


.admin_top_bar {
    overflow: hidden !important;
    display: flex !important;
    justify-content: space-between !important; /* Align items to the start and end of the container */
    overflow-y:hidden !important;
    overflow-x: auto !important;
    white-space: nowrap !important;
    scrollbar-width: none !important; 
    -ms-overflow-style: none !important; 
    padding: 0 !important;
    margin: 0 !important;
}

.topBar_left_sites {
    white-space: nowrap !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    display: flex;
    align-items: center !important;
    padding: 0;
    margin: 0;

}

.topBar_right_settings {
    display: flex;
    align-items: center !important;
    margin-left: auto; 
    justify-content: space-around;
    padding-right: 5px;
}


.admin_top_bar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


.admin_top_bar_button_left{
    height: 20px;
    width: 20px;
    cursor: pointer;
    content: url("../../../assets/icons/sideBar/arrowBack.svg");

}
.admin_top_bar_button_right{
    height: 20px;
    width: 20px;
    cursor: pointer;
    content: url("../../../assets/icons/sideBar/arrowBack.svg");
    transform: rotate(180deg);
   
}
.admin_top_bar_button_divider{
    height: 45px;
    width: 1.5px;
    background: #ced2e0;
}
.admin_top_bar_site_item:hover{
    background: #EBEEF7;
    border-radius: 8px;
}


.i {
    font-size: 1.75rem;
}

.side_bar{
    display:flex; 
    flex-direction:column; 
    position: fixed;
    background-color: var(--black);
    z-index: 1101;
    left:0;
    top: 0;
}

.side_bar_top_container{
   height: 60px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.side_bar_top_container_logo{
    width: 35px;
    height: 35px;
    content: url('../../../assets/icons/carteavLogo/carteavicon.svg');
}

.side_bar_top_container_logo_no_hover{
    width: 35px;
    height: 35px;
    content: url('../../../assets/icons/carteavLogo/carteavicon.svg');
}

.side_bar_top_container_logo:hover{
    cursor: pointer;
    width: 35px;
    height: 35px;
    content: url('../../../assets/icons/sideBar/arrow_hover.svg');

}
.side_bar_top_container_logo_phone{
    width: 35px;
    height: 35px;
    content: url('../../../assets/icons/carteavLogo/carteavicon.svg');
}
.side_bar_close_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 2px 0px 4px;
    width: 32px;
    height: 32px;
    background: rgba(0, 167, 238, 0.2);
    border-radius: 6px;
    cursor: pointer;
    color: #00A7EE;
}
.side_bar_close_button:hover{
    color: white;
    background:#00A7EE;

}

.side_bar_top_logo_text{
    color:white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.side_bar_content{
    position: relative;
    height: 44px;
    margin: 8px;
    top: 16px;
}

.side_bar_item:hover{
    background-color: #353146;
}



.side_bar_item{
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* width: 100%; */
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    

}
.side_bar_bottom_items{
    margin-top: auto;
    bottom: 5px;
    left: 0;
    border-top: 1px solid #212028;
}

.sites_events_number_circle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    padding: 10px 8px;
    height: 12px;
    gap: 4px;
    background: #EBEEF7;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #616E87;
    text-align: center;
}



/* Disable hover effect on tablets and touch devices */
@media (max-width: 1024px) {
    
    
    .setting_icon_active:hover{
        background-color:var(--carteav_light) !important;
        color:var(--carteav) !important;
    }

    .setting_icon:hover{
        background-color:#E4E6EB !important;
        box-shadow: none !important;
    }
    .admin_top_bar_button_left:hover{
        background-color: white !important;
        color:black !important;
    } 
  }


  /* Disable hover effect on tablets and touch devices */
@media (max-height: 500px) {
  

    .side_bar_bottom_items{
        height: 77px;
        position: absolute;
        bottom: 0px;
        left: 0;
    }
    .side_bar_content{
        position: relative;
        height: 34px;
        margin: 2px 8px;
        top: 0px;
    }
  }