:root {
  --carteav: #009ddb;
  --carteav_dark: #077eb1;
  --carteav_light: #00a7ee33;
  --white: #faf0e6;
  --black: #070A0E;
  --light_black: #24292e;
  --red:#FF3239;
  --light_red: hsl(357, 100%, 66%);
  --grey:#616E87;
  --text_grey: #c2c5ce;
  --light_grey: #EBEEF7;
  --green:#86E896;
  --light_green:#B2F9B2;
  --dark_green: #0DD02C;
  --input_color:#f9f2f2;
}





body {
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    /* Disable select text from app  */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #F0F0F3;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.display_center{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.center_column{
  display: flex;
  flex-direction: column;
  align-items: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

.leaflet-clickable {
  cursor: pointer !important;
}
/* Change cursor when over entire map */
.leaflet-container {
  cursor:  alias !important;
}

.leaflet-container:active {
  cursor:  pointer !important;
}

.inner_cirecle_car{
  height: 70px;
  width: 79px;
  
  border-radius: 50%;
  position: absolute;
  margin-top:35px;  
  background: rgba(255, 83, 104, 0.1);
  filter: blur(0.5px);
}
.dash-border{
  border: 2px dashed #3388ff;
  background-color: #3388ff4d;
}
.swal2-container {
  z-index: 10000000000 !important;
}

.my-custom-pin{
  z-index: 1000000000 !important;
}



.circle4 {
  border-radius:50%;
  position: relative;
  width: 100px;
  height: 100px;
  filter: blur(0.5px);
  position: absolute;
  background: rgba(93, 66, 255, 0.1);
  
}

.circle5 {
  border-radius:50%;
  transform: translate(25%, 25%);
  width: 60px;
  height: 60px;
  filter: blur(0.5px);
  position: absolute;
  background: rgba(93, 66, 255, 0.2);


}


  @keyframes circleCartAnimation3{ 
    0%{height: 105px;width: 105px;}
    50%{height: 155px;width: 155px;}
    100%{height: 105px;width: 105px;}

}


@keyframes circleCartAnimation2{ 
    0%{height: 55px;width: 55px;}
    50%{height: 85px;width: 85px;}
    100%{height: 55px;width: 55px;}

}

@keyframes circleCartAnimation{ 
    0%{height: 45px;width: 45px;}
    50%{height: 65px;width: 65px;}
    100%{height: 45px;width: 45px;}

}


.center_cart{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15 !important;
}

.anim-circle-error {
 width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 83, 104, 0.9);
  animation: breatheError 5s linear infinite;
  position: absolute;

}

@keyframes breatheError {
  0% {
    box-shadow: 0 0 0 5px rgba(255, 83, 104, 0.3), 0 0 0 10px rgba(255, 83, 104, 0.3), 0 0 0 15px rgba(255, 83, 104, 0.3), 0 0 0 20px rgba(255, 83, 104, 0.3), 0 0 0 25px rgba(255, 83, 104, 0.3);
  }
  50% {
    box-shadow: 0 0 0 7.5px rgba(255, 83, 104, 0.3), 0 0 0 15px rgba(255, 83, 104, 0.3), 0 0 0 22.5px rgba(255, 83, 104, 0.3), 0 0 0 30px rgba(255, 83, 104, 0.3), 0 0 0 37.5px rgba(255, 83, 104, 0.3);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(255, 83, 104, 0.3), 0 0 0 10px rgba(255, 83, 104, 0.3), 0 0 0 15px rgba(255, 83, 104, 0.3), 0 0 0 20px rgba(255, 83, 104, 0.3), 0 0 0 25px rgba(255, 83, 104, 0.3);
  }
}


.anim-circle-path {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(93, 66, 255, 0.9); /* Changed color */
  animation: breathePath 5s linear infinite;
  position: absolute;
}

@keyframes breathePath {
  0% {
    box-shadow: 0 0 0 5px rgba(93, 66, 255, 0.3), 0 0 0 10px rgba(93, 66, 255, 0.3), 0 0 0 15px rgba(93, 66, 255, 0.3), 0 0 0 20px rgba(93, 66, 255, 0.3), 0 0 0 25px rgba(93, 66, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 0 7.5px rgba(93, 66, 255, 0.3), 0 0 0 15px rgba(93, 66, 255, 0.3), 0 0 0 22.5px rgba(93, 66, 255, 0.3), 0 0 0 30px rgba(93, 66, 255, 0.3), 0 0 0 37.5px rgba(93, 66, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(93, 66, 255, 0.3), 0 0 0 10px rgba(93, 66, 255, 0.3), 0 0 0 15px rgba(93, 66, 255, 0.3), 0 0 0 20px rgba(93, 66, 255, 0.3), 0 0 0 25px rgba(93, 66, 255, 0.3);
  }
}


/* cart condig table - start   */
#adminActionHover .hiddenAdminActionsContainer{
  visibility: hidden;
}
#adminActionHover:hover .hiddenAdminActionsContainer{
  visibility: visible;
}
.black-scroll-bar-container ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.black-scroll-bar-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}


.black-scroll-bar-container ::-webkit-scrollbar-thumb {
  background: #070A0E;
}

.black-scroll-bar-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.black-scroll-bar-container::-webkit-scrollbar-x {
  width: 5px !important;
}

.black-scroll-bar-container::-webkit-scrollbar-track-x {
  background: #f1f1f1;
}

.black-scroll-bar-container::-webkit-scrollbar-thumb-x {
  background: #070A0E;
}

.black-scroll-bar-container::-webkit-scrollbar-thumb-x:hover {
  background: #555;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
     outline: none;
}






input[type=checkbox] {
  position: relative;
	border: 2px solid #000;
	border-radius: 2px;
	background: none;
	cursor: pointer;
	line-height: 0;
	margin: 0 .6em 0 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 18px;
	width: 18px;
	-webkit-appearance: none;
  opacity: .5;
}

input[type=checkbox]:hover {
  opacity: 1;
}

input[type=checkbox]:checked {
  background-color: rgb(49, 46, 46);
  opacity: 1;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2.3px 2.3px 0;
  margin: -1px -2px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}



/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  html {
    font-size: 14px; /* Change font size to 14px for screens up to 768px */
  }
}

/* Media query for larger screens */
@media screen and (min-width: 1200px) {
  html {
    font-size: 18px; /* Change font size to 18px for screens wider than 1200px */
  }
}

