
.split_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

.events_section,
.operators_section {
  flex: 1;
}

.operators_section {
    background: #f9f9f9;
  }
  
.operators_section:last-child {
border-bottom: none;
}
  
.operator_placeholder {
display: flex;
height: 100%;
color: #616e87;
font-size: 14px;
font-weight: 500;
}

.events_alerts_body_open {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: fixed;
    top: 60.5px;
    width: 300px;
    height: calc(100% - 60px);
    right: 0px;
    z-index: 999;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(9, 10, 15, 0.1);
}


.toggleButton {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 2px 0px 4px;
    gap: 8px;
    width: 32px;
    height: 32px;
    background: rgba(0, 167, 238, 0.2);
    border-radius: 6px;
    flex: none;
    border: 1;
    flex-grow: 0;
    cursor: pointer;
    color: var(--carteav);
    margin-left: 5px;

    
}

.toggleButton:hover {
    background-color: #007bb6;
    color: white;
    background:#00A7EE;
}


.cotainer_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 300px;
    height: 50px;
    
}

.center_row{
   height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.events_number_circle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    padding: 10px 8px;
    height: 10px;
    gap: 4px;
    background: #EBEEF7;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #616E87;
    text-align: center;
    position: relative;
    left: 8px;
}

.centeredActions {
   display: flex;
   justify-content: center !important;
   align-items: center !important;
  }
  

.search_bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 52px;
    position: relative;

}
.operator_event_item_close{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 268px;
    height: 48px;
    border-bottom: 1px solid #EBEEF7;
}

.operator_event_item_close_time{
    position: absolute;
    right:22px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #AAB0BD;
}

.operator_event_item_close_icon{
    position: absolute;
    left:22px;
}

.operator_event_item_close_title{
    position: absolute;
    left:10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.event_item_close{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 268px;
    height: 48px;
    border-bottom: 1px solid #EBEEF7;
}

.event_item_close_time{
    position: absolute;
    right:22px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #AAB0BD;
}

.event_item_close_icon{
    position: absolute;
    left:22px;
}

.event_item_close_title{
    position: absolute;
    left:47px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.event_item_open{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 20px;
    width: 300px;
    min-height: 128px;
    background: #F3F5FA;
    border: 1px solid #EBEEF7;
}

.event_item_open_body_text{
    position: relative;
    width: 268px;
    left: 10px;
    margin:1rem 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #616E87;
    margin-bottom: 1rem;
}

.event_item_open_body_header{
    position: relative;
    top: 10px;
    width: 268px;
    left: 10px;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 600;
}

.event_item_open_actions{
    position: relative;
    width: 268px;
    left: 10px;
    top:1rem;
    display: flex;
}

.assistCartButton {
    background-image: url("../../../assets/icons/event/buttons/assist.svg");
    width: 100px;
    height: 32px;
    background-repeat: no-repeat;
}

.assistCartButton:hover {
    background-image: url("../../../assets/icons/event/buttons/assistHover.svg");
    cursor: pointer;

}


.callBackButton{
    content: url("../../../assets/icons/event/buttons/callBack.svg");

}

.callBackButton:hover{
    cursor: pointer;
    content: url("../../../assets/icons/event/buttons/callBackHover.svg");

}
.focusOnCartButton{
    position: relative;
    left: 100px;
    content: url("../../../assets/icons/general/focusOnCartIcon.svg");
}

.focusOnCartButton:hover{
    cursor: pointer;
    content: url("../../../assets/icons/general/focusOnCartIconHover.svg");
}

.resolvedButton{
    position: absolute;
    cursor: pointer;
    bottom: 5px;
    right: 20px;
    content: url("../../../assets/icons/event/buttons/resolved.svg");
}


.dialogBodyText{
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1rem;
    color: #616E87;
    display: flex;
}

.blockedEventActionButton{
    margin: 5px; 
    padding: 5px 10px; 
    color: var(--carteav);
    font-weight: 500;
    font-size: 1rem; 
    border: none;
    background: none;
    cursor: pointer;
}

.malfunctionItemText{
    font-size: 0.9rem;
    color: var(--grey);
    margin-bottom: 1.5px;
}


.malfunctionActiveItemContainer{
    display:flex;
    flex-direction:column;
    width:250px;
    height:100px;
    justify-content: space-around;
    margin-bottom:1rem;
    border-bottom: 1px solid #AAB0BD ;
    padding-bottom: 1rem;
    padding-right: 1rem;
}
.malfunctionActiveItemContainer:last-child{
    border-bottom: none ;

}
.malfunctionLeftButtons{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    max-height: 90%;
    overflow: auto !important;
    width:180px;
    padding-top: 10px;

}

.malfunctionActiveBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90%;
    overflow: auto !important;
    padding-top: 10px;
}

.dialogCloeButton{
  margin-left: auto;
}

.dialog_header{
    display:flex;
    align-items: center;
    border-bottom: 1px solid var(--light_grey);
    height: 30px;
}

.dialog_body{
    height: min(70vh , 550px);
    display: flex;
    width: 100%;
    padding:10px 0;
    margin:0;
}

.dialogOperatorListActions{
    height: min(20vh , 200px);
    display: flex;
    width: 100%;
    padding:0;
    margin:0;
    font-size: 0.9rem;
    font-weight: 600;
    color:var(--light_red);
    overflow: auto;
}



@media (max-height: 800px) {
    .dialog_body{
        height: min(60vh , 450px);
    }
    
    .dialogOperatorListActions{
        height: min(15vh , 150px);
    }
}


